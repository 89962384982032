const registryForm = {
    cpf: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            required: true,
            placeholder: "___.___.___-__"
        },
        label: 'CPF*',
        value: '',
        validation: {
            required: true,
            minLength: 11,
            maxLength: 11,
            isNumeric: true
        },
        maskOptions: {
            mask: "999.999.999-99",
            placeholder: "___.___.___-__",
            clearIncomplete: true
        },
        mask: "",
        valid: false,
        touched: false
    },
    nome: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            required: true,
            minLength: 2,
            maxLength: 30,
        },
        label: 'Nome*',
        value: '',
        validation: {
            required: false,
            minLength: 2,
            maxLength: 30,
            isNumeric: false
        },
        mask: "",
        valid: false,
        touched: false
    },
    idade: {
        elementType: 'input',
        elementConfig: {
            type: 'date',
            required: true,
            minLength: 4,
            maxLength: 10,
            max: new Date().toISOString().split("T")[0]
        },
        label: 'Data de nascimento*',
        value: '',
        validation: {
            required: true,
            minLength: 4,
            maxLength: 10,
            isNumeric: true
        },
        mask: "00/00/0000",
        valid: false,
        touched: false
    },
    sexo: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: '', disabled: true, displayValue: 'Selecione' },
                { value: 'masculino', displayValue: 'Masculino' },
                { value: 'feminino', displayValue: 'Feminino' }
            ],
            required: true
        },
        label: 'Sexo*',
        value: '',
        validation: {
            required: true
        },
        valid: false
    },
    jejum: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            required: true,
            min: 0
        },
        label: 'Horas em jejum*',
        value: '',
        validation: {
            required: false
        },
        valid: true
    },
    // medicamentos: {
    //     elementType: 'input',
    //     elementConfig: {                
    //     },
    //     label: 'Toma medicamentos?',
    //     value: '',
    //     validation: {
    //         required: false
    //     },
    //     valid: true
    // }, 
    etnia: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: '', disabled: true, displayValue: 'Selecione' },
                { value: 'Afro-descendente', displayValue: 'Afro-descendente' },
                { value: 'Indígena', displayValue: 'Indígena' },
                { value: 'Amarelo', displayValue: 'Amarelo' },
                { value: 'Negro', displayValue: 'Negro' },
                { value: 'Caucasiano', displayValue: 'Caucasiano' },
                { value: 'Pardo', displayValue: 'Pardo' }
            ],
            required: true
        },
        label: 'Etnia*',
        value: '',
        validation: {
            required: true
        },
        valid: false
    },
    obs: {
        elementType: 'textarea',
        elementConfig: {
            type: 'text',
            placeholder: 'Observacões'
        },
        label: 'Obs:',
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    },
    numero_de_pareamento: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            required: false,
            minLength: 2,
            maxLength: 30,
        },
        label: 'Número de pareamento',
        value: '',
        validation: {
            required: false,
            minLength: 2,
            maxLength: 30,
            isNumeric: false
        },
        mask: "",
        valid: false,
        touched: false
    },
    // patologias: {
    //     cardiovascular: {
    //         elementType: 'checkbox',                
    //         label: 'Cardiovascular',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     }, 
    //     respiratorio: {
    //         elementType: 'checkbox',                
    //         label: 'Respiratório',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     digestorio: {
    //         elementType: 'checkbox',               
    //         label: 'Respiratório',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     nervoso: {
    //         elementType: 'checkbox',              
    //         label: 'Nervoso',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     }, 
    //     endocrino: {
    //         elementType: 'checkbox',              
    //         label: 'Endócrino',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     excretor: {
    //         elementType: 'checkbox',               
    //         label: 'Excretor',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     urinario: {
    //         elementType: 'checkbox',                
    //         label: 'Urinário',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     esqueletico: {
    //         elementType: 'checkbox',              
    //         label: 'Esquelético/ Muscular',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     imunologico: {
    //         elementType: 'checkbox',              
    //         label: 'Imunológico',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     linfatico: {
    //         elementType: 'checkbox',              
    //         label: 'Linfático',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },
    //     naoespeciticado: {
    //         elementType: 'checkbox',              
    //         label: 'Não especificado',
    //         value: '',
    //         validation: {
    //             required: false
    //         },
    //         valid: true
    //     },

    // },
}

export default registryForm;